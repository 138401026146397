
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SubmitButton from "@/components/SubmitButton.vue";

export default defineComponent({
  name: "AdminResourceBannerLinkCreateModal",
  components: { SubmitButton },
  props: {
    resource: Object,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    let isLoading = computed(() => {
      return store.getters[Modules.MASTER + "getIsLoading"];
    });

    let zones = ref([]);
    let banners = ref([]);
    let resource = ref(props.resource);

    const modalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLButtonElement | null>(null);

    const schema = yup.object({
      zone_id: yup.string().required().label("Zone"),
      banner_id: yup.string().required().label("Banner"),
    });

    const { meta, errors, handleSubmit, isSubmitting, setErrors } = useForm({
      initialValues: {
        zone_id: "",
        banner_id: "",
      },
      validationSchema: schema,
    });

    const { value: zone_id } = useField("zone_id");
    const { value: banner_id } = useField("banner_id");

    const onSubmit = handleSubmit((values) => {
      let payload = {
        resourceID: resource.value?.id,
        values: values,
      };
      return new Promise<void>((resolve) => {
        store
          .dispatch(Modules.MASTER + Actions.STORE_RESOURCE_BANNER, payload)
          .then(() => {
            Swal.fire({
              text: store.getters[Modules.MASTER + "getSuccessMessage"],
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              hideModal(modalRef.value);
              store.dispatch(Modules.MASTER + Actions.FETCH_RESOURCES, {
                search: "",
                url: "",
              });
            });
          })
          .catch(() => {
            setErrors(store.getters[Modules.MASTER + "getErrors"]);
            Swal.fire({
              text: "Failed to create the resource. Please fix the errors.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          })
          .finally(() => {
            zone_id.value = "";
            banner_id.value = "";
            resolve();
          });
      });
    });

    watch(
      () => props.resource,
      (newValue) => {
        resource.value = newValue;
        zone_id.value = "";
        banner_id.value = "";
      }
    );

    function fetchZones() {
      store.dispatch(Modules.MASTER + Actions.FETCH_ALL_ZONES).then((data) => {
        zones.value = data;
      });
    }

    function fetchBanners() {
      store
        .dispatch(Modules.MASTER + Actions.FETCH_ALL_BANNERS, zone_id.value)
        .then((data) => {
          banners.value = data;
        });
    }

    fetchZones();

    return {
      isLoading,
      zone_id,
      banner_id,

      zones,
      banners,
      fetchBanners,

      modalRef,
      submitButton,

      meta,
      errors,
      onSubmit,
      isSubmitting,
    };
  },
});
